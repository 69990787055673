import React, { useEffect, useState } from "react";
import {
  DialogActions,
  Dialog,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import { Formik } from "formik";
import { Bot } from "../../assets";
import RemoveIcon from "@mui/icons-material/Remove";
import Select, { components } from "react-select";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useDispatch, useSelector } from "react-redux";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import {
  createQuestion,
  updateNextQuestion,
  updateQuestion,
} from "../../slices/question";
import constants from "../../utilities/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getDepartments } from "../../slices/operaters";
import EditorComponent from "../EditorComponent";
const CreateBotQuestion = ({ id, open, handleClose, data }) => {
  const dispatch = useDispatch();
  const [submittedContent, setSubmittedContent] = useState("");
  const { t } = useTranslation();
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "#fff" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };
  const [checkBoxChoices, setCheckBoxChoices] = useState({
    content: "",
    department: null,
  });
  const [checkboxLabel, setCheckBoxLabel] = useState("");
  const [selectLabel, setSelectLabel] = useState("");
  const { user } = useSelector((state) => state.user);
  const { departments } = useSelector((state) => state.operators);
  const [botChoices, setBotChoices] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [notify, setNotify] = useState(false);

  const [selectedValue, setSelectedValue] = useState({
    label: "input",
    value: "input",
  });
  const [department, setDepartment] = useState(null);
  const [botCheckboxChoices, setBotCheckboxChoices] = useState([]);
  const initialValues = {
    question: "",
    type: "",
    order: "",
    label: "",
    assign: isSelected,
    notify,
    department: department,
  };

  const UpdateQuestion = {
    question: data?.data?.tradingName,
    type: data?.data?.type === undefined ? "input" : data?.data?.type,
    order: data?.data?.order,
    label: data?.data?.label,
    assign: data?.data?.assign,
    notify: data?.data?.notify,
  };
  useEffect(() => {
    if (botChoices?.find((el) => el?.department)) {
      setIsSelected(true);
    }
  }, [botChoices]);
  useEffect(() => {
    if (isSelected) {
      setSelectedValue({
        label: "select",
        value: "select",
      });
    }
  }, [isSelected]);
  useEffect(() => {
    let UpdateQuestion = {};
    UpdateQuestion.question =
      data?.data?.tradingName !== undefined && data?.data?.tradingName;
    UpdateQuestion.order = data?.data?.order;
  }, [data]);

  useEffect(() => {
    if (data?.data?.tradingName !== undefined) {
      setNotify(data?.data?.notify);
      setSelectedValue({
        label: data?.data?.type === undefined ? "input" : data?.data?.type,
        value: data?.data?.type === undefined ? "input" : data?.data?.type,
      });
      if (data?.data?.type === "checkBox") {
        setBotCheckboxChoices(
          data?.data?.choices.map((choice) => {
            return {
              choice: choice.content,
              department: choice?.department ? choice?.department : undefined,
              id: choice._id,
              label: choice.label,
              next: choice?.next ? choice.next : undefined,
            };
          })
        );
      } else {
        if (data?.data?.type === "select") {
          setBotChoices(
            data?.data?.choices.map((choice) => {
              return {
                choice: choice.content,
                department: choice?.department ? choice?.department : undefined,
                id: choice._id,
                label: choice.label,
                next: choice?.next ? choice.next : undefined,
              };
            })
          );
        }
      }
    }
  }, [data]);
  const { updateStatus, status, updatenextQuestionStatus } = useSelector(
    (state) => state.question
  );
  const Option = (props) => {
    return (
      <components.Option {...props} className="select-option">
        <div className="radio-btn-label-wrapper">
          <div className="select-option-label">{props.label}</div>
        </div>
      </components.Option>
    );
  };
  const [choice, setChoice] = useState({ content: "", department: null });

  const handleSelectChange = (e) => {
    setSelectedValue(e);
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <KeyboardArrowUpIcon />
        ) : (
          <KeyboardArrowDownIcon />
        )}
      </components.DropdownIndicator>
    );
  };

  const styles = {
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      // transform: state.isFocused ? "rotate(180deg)" : null,
    }),
    control: (base, state) => ({
      ...base,
      border: "2px solid #ebebeb",
      borderRadius: "8px",
      height: "50px",
      backgroundColor: "white",
      borderbottom: state.isFocused ? "unset" : "1px solid #ebebeb",
      boxShadow: state.isFocused ? "0px " : "0px",
      "&:hover": {
        ...styles,
      },
    }),
    menu: ({ width, ...css }) => ({
      ...css,
      width: "100%",
      boxShadow: "0px",
      borderRadius: "8px",
      border: "1px solid #ebebeb",
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: "normal",
      color: "#78787A",
      width: "100%",
      backgroundColor: "white",
      fontSize: "12px",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#78787A",
      width: "100%",
      fontSize: "12px",
    }),
  };
  const { i18n } = useTranslation();
  return (
    <Dialog
      open={open}
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
      onClose={() => {
        setBotChoices([]);
        setCheckBoxLabel("");
        setSelectLabel("");
        setSelectedValue({
          label: "input",
          value: "input",
        });
        setBotCheckboxChoices([]);
        setCheckBoxChoices([]);
        setChoice("");
        setIsSelected(false);
        setNotify(false);
        setDepartment(null);
        handleClose(id);
      }}
      PaperProps={{
        style: { borderRadius: 15 },
      }}
    >
      <Formik
        initialValues={
          data?.data?.tradingName !== undefined ? UpdateQuestion : initialValues
        }
        validationSchema={yup.object({
          question: yup.string().trim().required(t("Question Is Required")),
          label:
            data?.update !== true
              ? yup.string().trim().required(t("Question Topic Is Required"))
              : yup
                  .string()
                  .trim()
                  .notRequired(t("Question Topic Is Required")),
          order:
            data?.next !== true &&
            data?.update !== true &&
            yup
              .number()
              .required(t("The Order Of The Question Is Required"))
              .test(
                "max",
                (val) =>
                  val === 2 ||
                  (val === 1 && data?.lastQuestion?.order === undefined)
              ),
        })}
        onSubmit={async (values) => {
          try {
            if (data?.next === true) {
              dispatch(
                updateNextQuestion({
                  content: values.question,
                  websiteID: user.websiteID,
                  label: values.label,
                  id: data?.id,
                  notify,
                  assign:
                    (botChoices?.find((el) => el?.department) && true) ||
                    (botCheckboxChoices?.find((el) => el?.department) && true),
                  type: selectedValue.value,
                  choices: selectedValue.label?.includes("checkBox")
                    ? botCheckboxChoices.map((item) => {
                        return {
                          label: item?.label,
                          department: item?.department?.value
                            ? item?.department?.value
                            : item?.department?._id
                            ? item?.department?._id
                            : undefined,
                          content: item?.choice,
                        };
                      })
                    : selectedValue.label?.includes("select")
                    ? botChoices.map((item) => {
                        return {
                          label: item?.label,
                          content: item?.choice,
                          department: item?.department?.value
                            ? item?.department?.value
                            : item?.department?._id
                            ? item?.department?._id
                            : undefined,
                        };
                      })
                    : "",
                })
              ).then((res) => {
                res?.error
                  ? showSnackbar({
                      variant: "error",
                      message: t(res?.error?.message),
                    })
                  : (() => {
                      showSnackbar({
                        variant: "success",
                        message: t("question created successfully"),
                      });
                      handleClose(id);
                      setBotChoices([]);
                      setSelectedValue({
                        label: "input",
                        value: "input",
                      });
                      setCheckBoxChoices([]);
                      setBotCheckboxChoices([]);
                      setCheckBoxLabel("");
                      setSelectLabel("");
                    })();
              });
            } else {
              if (data.update === undefined) {
                dispatch(
                  createQuestion({
                    values: values.question,
                    order: values.order,
                    label: values.label,
                    websiteID: user.websiteID,
                    type: selectedValue.value,
                    notify,
                    assign:
                      selectedValue.label?.includes("select") &&
                      botChoices?.find((el) => el?.department)
                        ? true
                        : selectedValue.label?.includes("checkBox") &&
                          botCheckboxChoices?.find((el) => el?.department) &&
                          true,
                    choices: selectedValue.label?.includes("checkBox")
                      ? botCheckboxChoices.map((item) => {
                          return {
                            label: item?.label,
                            content: item?.choice,
                            department: item?.department?.value
                              ? item?.department?.value
                              : item?.department?._id
                              ? item?.department?._id
                              : undefined,
                          };
                        })
                      : selectedValue.label?.includes("select")
                      ? botChoices.map((item) => {
                          return {
                            label: item?.label,
                            content: item?.choice,
                            department: item?.department?.value
                              ? item?.department?.value
                              : item?.department?._id
                              ? item?.department?._id
                              : undefined,
                          };
                        })
                      : "",
                  })
                ).then((res) => {
                  res?.error
                    ? showSnackbar({
                        variant: "error",
                        message: res?.error?.message,
                      })
                    : (() => {
                        showSnackbar({
                          variant: "success",
                          message: t("question created successfully"),
                        });
                        setBotChoices([]);
                        setSelectedValue({
                          label: "input",
                          value: "input",
                        });
                        setCheckBoxChoices([]);
                        setBotCheckboxChoices([]);
                        setCheckBoxLabel("");
                        setIsSelected(false);
                        setNotify(false);
                        setDepartment(null);
                        setSelectLabel("");
                        handleClose(id);
                      })();
                });
              } else {
                dispatch(
                  updateQuestion({
                    id: data.data.id,
                    websiteID: user.websiteID,
                    content: values.question,
                    label: values.label,
                    notify,
                    assign:
                      selectedValue.label?.includes("select") &&
                      botChoices?.find((el) => el?.department)
                        ? true
                        : selectedValue.label?.includes("checkBox") &&
                          botCheckboxChoices?.find((el) => el?.department) &&
                          true,
                    type: selectedValue.label?.includes("checkBox")
                      ? selectedValue.label
                      : selectedValue.label?.includes("select")
                      ? selectedValue.label
                      : selectedValue.label?.includes("message")
                      ? selectedValue.label
                      : "input",
                    choices: selectedValue.label?.includes("checkBox")
                      ? botCheckboxChoices.map((item) => {
                          return {
                            department: item?.department?.value
                              ? item?.department?.value
                              : item?.department?._id
                              ? item?.department?._id
                              : undefined,
                            label: item?.label,
                            content: item?.choice,
                            id: item.id,
                            next: item?.next ? item.next : undefined,
                          };
                        })
                      : selectedValue.label?.includes("select")
                      ? botChoices.map((item) => {
                          return {
                            //        label: item?.label,
                            content: item?.choice,
                            department: item?.department?.value
                              ? item?.department?.value
                              : item?.department?._id
                              ? item?.department?._id
                              : undefined,
                            id: item.id,
                            next: item?.next ? item.next : undefined,
                          };
                        })
                      : "",
                  })
                ).then((res) => {
                  res?.error
                    ? showSnackbar({
                        variant: "error",
                        message: res?.error?.message,
                      })
                    : (() => {
                        showSnackbar({
                          variant: "success",
                          message: t("question updated successfully"),
                        });
                        setBotChoices([]);
                        setSelectedValue({
                          label: "input",
                          value: "input",
                        });
                        setCheckBoxChoices([]);
                        setBotCheckboxChoices([]);
                        setCheckBoxLabel("");
                        setIsSelected(false);
                        setNotify(false);
                        setDepartment(null);
                        setSelectLabel("");
                        handleClose(id);
                      })();
                });
              }
            }
          } catch (err) {}
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div id="Bot-modal">
              <div className="bot-modal-image-title">
                <LazyLoadImage id="question-bot" src={Bot} alt={"Bot"} />
                <DialogTitle className="assign-operator-title">
                  {t(
                    data?.update === undefined
                      ? t("Create Bot Question")
                      : data?.update === true
                      ? t("Update Bot Question")
                      : data?.next === true && t("Create Bot SubQuestion")
                  )}
                </DialogTitle>
              </div>
              <label htmlFor="create-bot-question-TextField">
                {t("Question")} :
              </label>
              <EditorComponent />
              {/* <TextField
                id="create-bot-question-TextField"
                placeholder={t("Enter Your Bot Question")}
                fullWidth
                value={values.question}
                onChange={handleChange}
                error={Boolean(touched.question && errors.question)}
                helperText={touched.question && errors.question}
                onBlur={handleBlur}
                name="question"
                type="text"
                InputProps={{
                  className: "create-bot-question-TextField",
                }}
              /> */}
              <label htmlFor="create-bot-question-TextField">
                {t("Question Topic")} :
              </label>
              <TextField
                id="create-bot-question-TextField"
                fullWidth
                placeholder={t("Enter Your Bot Label")}
                value={values.label}
                onChange={handleChange}
                name="label"
                error={Boolean(touched.label && errors.label)}
                helperText={touched.label && errors.label}
                onBlur={handleBlur}
                InputProps={{
                  className: "create-bot-question-TextField",
                }}
              />

              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label htmlFor="create-bot-question-checkbox">
                  {t("Assign department")} :
                </label>
                <Checkbox
                  sx={{
                    width: "fit-content",
                    color: "#EDE6FE",
                    "&.Mui-checked": {
                      color: "#6932FA",
                    },
                  }}
                  id="create-bot-question-checkbox"
                  name="checkbox"
                  checked={isSelected}
                  onChange={() => {
                    handleChange;
                    setIsSelected((prev) => !prev);

                    departments?.length == 0 &&
                      dispatch(
                        getDepartments({
                          websiteID: user?.websiteID,
                          page: 1,
                          limit: 0,
                        })
                      );
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              {isSelected && (
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <label htmlFor="create-bot-question-checkbox">
                    {t("Notify operators by email")} :
                  </label>
                  <Checkbox
                    sx={{
                      width: "fit-content",
                      color: "#EDE6FE",
                      "&.Mui-checked": {
                        color: "#6932FA",
                      },
                    }}
                    id="create-bot-question-checkbox"
                    name="checkbox"
                    checked={notify}
                    onChange={() => {
                      handleChange;
                      setNotify((prev) => !prev);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              )}

              {data?.next !== true && data.update !== true && (
                <>
                  <label htmlFor="create-bot-question-TextField">
                    {t("Question Order")} :
                  </label>
                  <TextField
                    id="create-bot-question-TextField"
                    placeholder={t("Enter Question Order")}
                    value={values.order}
                    type="number"
                    fullWidth
                    onChange={handleChange}
                    error={Boolean(touched.order && errors.order)}
                    helperText={touched.order && errors.order}
                    onBlur={handleBlur}
                    name="order"
                    InputProps={{
                      className: "create-bot-question-TextField",
                    }}
                  />
                </>
              )}
              {(data?.next === null ||
                data?.data?.next === null ||
                data.isEntryGate === true ||
                data.update.toString() === "true") && (
                <>
                  <label>{t("Question Type")} :</label>
                  <Select
                    value={{ label: selectedValue.label }}
                    styles={styles}
                    style={{ height: "150px", width: "100%" }}
                    placeholder={<div>Type to search</div>}
                    onChange={handleSelectChange}
                    components={{ Option, DropdownIndicator }}
                    options={
                      isSelected
                        ? constants.BOTOPTIONS?.filter((el) =>
                            ["select", "checkbox"]?.includes(
                              el?.label?.toLocaleLowerCase()
                            )
                          )
                        : constants.BOTOPTIONS
                    }
                  />
                </>
              )}

              {selectedValue.label?.includes("select") &&
                selectedValue.label?.includes("select") !== undefined && (
                  <div className="add-choice">
                    <TextField
                      id="create-bot-question-TextField"
                      placeholder={t("Enter Select choices")}
                      name="choice"
                      value={choice?.content}
                      fullWidth
                      onChange={(e) => setChoice({ content: e.target.value })}
                      error={Boolean(touched.choice && errors.choice)}
                      helperText={touched.choice && errors.choice}
                      onBlur={handleBlur}
                      InputProps={{
                        className: "create-bot-question-TextField",
                      }}
                    />
                    {isSelected && (
                      <Select
                        value={{
                          label: department?.label || "Select a department",
                        }}
                        styles={styles}
                        style={{ height: "150px", width: "100%" }}
                        required
                        placeholder={<div>Type to search</div>}
                        onChange={(selectedOption) => {
                          handleSelectChange;
                          setDepartment(selectedOption);
                          setChoice((prev) => ({
                            ...prev,
                            department: selectedOption,
                          }));
                          setFieldValue("department", selectedOption?.value);
                        }}
                        components={{ Option, DropdownIndicator }}
                        options={departments.map((department) => ({
                          label: department.name,
                          value: department._id,
                        }))}
                      />
                    )}

                    <AddCircleIcon
                      style={{ color: "#6932F8" }}
                      onClick={() => {
                        if (
                          choice?.content?.trim()?.length === 0
                          // || selectLabel.length === 0
                        ) {
                          showSnackbar({
                            variant: "error",
                            message: t("Choice or label must not be empty"),
                          });
                        } else {
                          setBotChoices((prev) =>
                            prev.concat({
                              id: Math.random(),
                              choice: choice?.content,
                              department: choice?.department,
                              label: selectLabel,
                            })
                          );
                        }
                        setChoice({ content: "" });
                        setDepartment(null);
                        setSelectLabel("");
                      }}
                    />
                  </div>
                )}
              {selectedValue.label?.includes("checkBox") &&
                selectedValue.label?.includes("checkBox") !== undefined && (
                  <div className="add-choice">
                    <TextField
                      id="create-bot-question-TextField"
                      placeholder={t("Enter Select choices Label")}
                      name="choiceLabel"
                      value={checkboxLabel}
                      fullWidth
                      onChange={(e) => setCheckBoxLabel(e.target.value)}
                      error={Boolean(touched.choice && errors.choice)}
                      helperText={touched.choice && errors.choice}
                      onBlur={handleBlur}
                      InputProps={{
                        className: "create-bot-question-TextField",
                      }}
                    />
                    <TextField
                      id="create-bot-question-TextField"
                      placeholder={t("Enter Select choices")}
                      name="choice"
                      value={checkBoxChoices?.content}
                      fullWidth
                      onChange={(e) =>
                        setCheckBoxChoices({ content: e.target.value })
                      }
                      error={Boolean(touched.choice && errors.choice)}
                      helperText={touched.choice && errors.choice}
                      onBlur={handleBlur}
                      InputProps={{
                        className: "create-bot-question-TextField",
                      }}
                    />
                    {isSelected && (
                      <Select
                        value={{
                          label: department?.label || "Select a department",
                        }}
                        styles={styles}
                        style={{ height: "150px", width: "100%" }}
                        required
                        placeholder={<div>Type to search</div>}
                        onChange={(selectedOption) => {
                          handleSelectChange;
                          setFieldValue("department", selectedOption?.value);
                          setDepartment(selectedOption);
                          setCheckBoxChoices((prev) => ({
                            ...prev,
                            department: selectedOption,
                          }));
                        }}
                        components={{ Option, DropdownIndicator }}
                        options={departments.map((department) => ({
                          label: department.name,
                          value: department._id,
                        }))}
                      />
                    )}
                    <AddCircleIcon
                      style={{ color: "#6932F8" }}
                      onClick={() => {
                        if (
                          checkBoxChoices?.content?.length === 0 ||
                          checkboxLabel?.trim()?.length === 0
                        ) {
                          showSnackbar({
                            variant: "error",
                            message: t("Choice or label must not be empty"),
                          });
                        } else {
                          setBotCheckboxChoices((prev) =>
                            prev.concat({
                              id: Math.random(),
                              choice: checkBoxChoices?.content,
                              department: checkBoxChoices?.department
                                ? checkBoxChoices?.department
                                : undefined,
                              label: checkboxLabel,
                            })
                          );
                        }
                        setCheckBoxChoices({ content: "" });
                        setCheckBoxLabel("");
                        setDepartment(null);
                      }}
                    />
                  </div>
                )}

              {selectedValue.label?.includes("select") &&
                selectedValue.label?.includes("select") !== undefined &&
                botChoices?.map((item) => {
                  return (
                    <div className="choices-wrapper">
                      {/* <span>
                        {" "}
                        <span className="choices-label">{t("Label")}</span>
                        &nbsp;&nbsp;{item.label}
                      </span> */}
                      {/* <div className="remove-choice"> */}
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <span className="choices-label">{t("Choice")}</span>
                        {/* &nbsp;&nbsp; */}
                        <span>{item.choice}</span>
                      </div>
                      {item?.department && (
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                        >
                          <span className="choices-label">
                            {t("Department")}
                          </span>{" "}
                          <Select
                            value={{
                              label:
                                item?.department?.name ||
                                item?.department?.label ||
                                department?.label ||
                                "Select a department",
                            }}
                            styles={styles}
                            style={{ height: "150px", width: "100%" }}
                            required
                            placeholder={<div>Type to search</div>}
                            onChange={(selectedOption) => {
                              handleSelectChange;
                              setFieldValue(
                                "department",
                                selectedOption?.value
                              );
                              setBotChoices((prev) => {
                                const updatedChoices = prev.map((el) =>
                                  el?.id === item?.id
                                    ? { ...el, department: selectedOption }
                                    : el
                                );
                                return updatedChoices;
                              });
                            }}
                            components={{ Option, DropdownIndicator }}
                            options={departments.map((department) => ({
                              label: department.name,
                              value: department._id,
                            }))}
                          />
                        </div>
                      )}
                      {/* </div> */}
                      <RemoveIcon
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() =>
                          setBotChoices((prev) =>
                            prev.filter((el) => el.id !== item.id)
                          )
                        }
                      />
                    </div>
                  );
                })}

              {selectedValue.label?.includes("checkBox") &&
                selectedValue.label?.includes("checkBox") !== undefined &&
                botCheckboxChoices.map((item) => {
                  return (
                    <div className="choices-wrapper">
                      <span>
                        <span className="choices-label">{t("Label")}</span>
                        &nbsp;&nbsp;{item.label}
                      </span>
                      <div className="remove-choice">
                        <span>
                          <span className="choices-label">{t("Choice")}</span>
                          &nbsp;&nbsp;{item.choice}
                        </span>
                      </div>
                      {item?.department && (
                        <div className="department-choice-container">
                          <span className="choices-label">
                            {t("Department")}
                          </span>

                          <Select
                            value={{
                              label:
                                item?.department?.name ||
                                item?.department?.label ||
                                "Select a department",
                            }}
                            styles={styles}
                            style={{ height: "150px", width: "100%" }}
                            required
                            placeholder={<div>Type to search</div>}
                            onChange={(selectedOption) => {
                              handleSelectChange;
                              setBotCheckboxChoices((prev) => {
                                const updatedChoices = prev?.map((el) =>
                                  el?.id === item?.id
                                    ? { ...el, department: selectedOption }
                                    : el
                                );
                                return updatedChoices;
                              });
                            }}
                            components={{ Option, DropdownIndicator }}
                            options={departments.map((department) => ({
                              label: department.name,
                              value: department._id,
                            }))}
                          />
                        </div>
                      )}
                      <RemoveIcon
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() =>
                          setBotCheckboxChoices((prev) =>
                            prev.filter((el) => el.id !== item.id)
                          )
                        }
                      />
                    </div>
                  );
                })}
            </div>
            <DialogActions className="assign-operator-btns">
              <Button
                id="cancel-create-bot-question"
                onClick={() => {
                  setBotChoices([]);
                  setCheckBoxChoices([]);
                  setCheckBoxLabel("");
                  setSelectLabel("");
                  setSelectedValue({
                    label: "input",
                    value: "input",
                  });
                  setBotCheckboxChoices([]);
                  setChoice("");
                  handleClose(id);
                  setDepartment(null);
                  setIsSelected(false);
                }}
              >
                {t("Cancel")}
              </Button>
              <Button
                id="add-question-bot-btn"
                disabled={
                  (status === "loading" && data?.update === undefined) ||
                  (updatenextQuestionStatus === "loading" && data?.next) ||
                  (data?.update !== undefined && updateStatus === "loading") ||
                  (selectedValue.label?.includes("checkBox") &&
                    botCheckboxChoices?.length === 0) ||
                  (selectedValue.label?.includes("select") &&
                    botChoices?.length === 0)
                }
                className={
                  (status === "loading" && data?.update === undefined) ||
                  (updatenextQuestionStatus === "loading" && data?.next) ||
                  (data?.update !== undefined && updateStatus === "loading") ||
                  (selectedValue.label?.includes("checkBox") &&
                    botCheckboxChoices?.length === 0) ||
                  (selectedValue.label?.includes("select") &&
                    botChoices?.length === 0)
                    ? "diabled-bot-btn"
                    : ""
                }
                color="primary"
                type="submit"
              >
                {data?.update === undefined && status === "loading" ? (
                  <CircularProgress
                    style={{
                      width: "25px",
                      height: "25px",
                      color: "#fff",
                    }}
                  />
                ) : data?.update !== undefined && updateStatus === "loading" ? (
                  <CircularProgress
                    style={{
                      width: "25px",
                      height: "25px",
                      color: "#fff",
                    }}
                  />
                ) : updatenextQuestionStatus === "loading" && data?.next ? (
                  <CircularProgress
                    style={{
                      width: "25px",
                      height: "25px",
                      color: "#fff",
                    }}
                  />
                ) : data?.update === undefined ? (
                  t("Create")
                ) : (
                  t("Edit")
                )}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
export default CreateBotQuestion;
